/* 
Author: Zankat Kalpesh
Email: zankatkalpesh@gmail.com
*/

import { Http } from '../Core/Services/HttpService';
import {
  ModelErrorType,
  MyModelEntity,
  MyModelError,
  MyModelService
} from "../Core/Services/MyModelService";

/* Define Param Type */
export type LoginParam = {
  Email: string;
  Password: string;
}

export type OtpParam = {
  Email: string;
  Otp: string;
}

/* Entity */

export class User extends MyModelEntity {

  id!: number;
  role_id!: number;
  first_name!: string;
  last_name!: string;
  email!: string;
  mobile!: string;
  discom_id!: number;
  discom_ids!: any;
  discom_name!: string;
  state_id!: any;
  state_name!: string;
  short_form!: string;
  type_of_entity!: string;
  status!: string;
  is_password_reset!: number;
  nodal_agency!: number;
  user_nodal_agency!: number;
  login_token!: string;
  login_token_at!: string;
  is_multi_discom!: number;
  user_state_id!: any;

  constructor(data?: any) {
    super(data);
    if (data) {
      this.objectAssign(this, data);
    }
  }

  get discom_user_id(): number {
    return this.id;
  };

  get fullName(): string {
    return [this.first_name, this.last_name].join(' ');
  }

  get Token(): string {
    return this.login_token;
  }

  get TokenAt(): string {
    return this.login_token_at;
  }

}

/* Model */
export class UserService extends MyModelService<any> {

  constructor(url?: string) {
    super();
    super.setUrl(url || '');
  }

  /**
   * Login
   * @param params (type LoginParam)
   * @returns Promise\<User>
   */
  login(params: LoginParam): Promise<User> {
    return new Promise((resolve, reject) => {
      Http.post('/login', params)
        .then((res) => {
          if (res.data.code === "200" && res.data.flag === true) {
            resolve(new User(res.data.data));
          } else {
            reject(new MyModelError(ModelErrorType.CLIENT, res.data));
          }
        })
        .catch((e) => reject(new MyModelError(ModelErrorType.SERVER, e)));
    });
  }

  /**
   * Otp Verify
   * @param params (type OtpParam)
   * @returns Promise\<User>
   */
  otpVerify(params: OtpParam): Promise<User> {
    return new Promise((resolve, reject) => {
      Http.post('/otpVerify', params)
        .then((res) => {
          if (res.data.code === "200" && res.data.flag === true) {
            resolve(new User(res.data));
          } else {
            reject(new MyModelError(ModelErrorType.CLIENT, res.data));
          }
        })
        .catch((e) => reject(new MyModelError(ModelErrorType.SERVER, e)));
    });
  }

}