import React, { useEffect, useState } from 'react'
import DisbursementDetails from "./DisbursementDetails";
import CurrentUtilizationStatus from "./CurrentUtilizationStatus";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Storage } from '../../Core/Services/StorageService';

const FundUtilization = () => {
    const [selected, setSelected] = useState("RequestForFundRelease");
    const [discomid, setDiscomId] = useState<any>(0);
    const [roleid, setRoleid] = useState<any>(0);
    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        setSelected("DisbursementDetails");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        setDiscomId(Storage.get('discom_id'));
        setRoleid(Storage.get('roleid'));
    }, [discomid]);

    useEffect(() => {
        if (roleid == 5 || roleid == 6) {
            if (discomid == 0 || discomid == '') {
                navigate('/nodal-view');
            } else {
                navigate('/fund-utilization');
            }
        }
    }, [discomid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            <li>Fund Disbursal</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew fd-tab-top">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection pb-1'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className='col-lg-12 col-xl-7 col-xxl-5'>
                                                <div className="radioYear h-auto">
                                                    <div className="row justify-content-center align-items-center gx-0 mx-0">
                                                        <div className="col-md-6">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="DisbursementDetails" value="DisbursementDetails" id="DisbursementDetails" checked={selected === "DisbursementDetails"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="DisbursementDetails">
                                                                    Disbursement Details
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="CurrentUtilizationStatus" value="CurrentUtilizationStatus" id="CurrentUtilizationStatus" checked={selected === "CurrentUtilizationStatus"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="CurrentUtilizationStatus">
                                                                    Current Utilization Status
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div aria-hidden={selected !== "DisbursementDetails" ? true : false}><DisbursementDetails /></div>
                                    <div aria-hidden={selected !== "CurrentUtilizationStatus" ? true : false}><CurrentUtilizationStatus /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    );
}

export default FundUtilization;