import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { Http } from '../../Core/Services/HttpService';
import InnerTemplate from "../../Components/InnerTemplate/InnerTemplate";
import Select from "react-select";
import {Link} from "react-router-dom";
import {BsChevronCompactLeft, BsChevronCompactRight, BsChevronDoubleLeft, BsChevronDoubleRight} from "react-icons/bs";
import SummaryReports from "./SummaryReports";
import AllTendersReport from "./AllTendersReport";
import AwardedTendersReport from "./AwardedTendersReport";

const MisAwardReport = (props: any) => {
    let currentYearValue = new Date().getFullYear();
    const [reportType, setReportType] = useState<any>('SummaryReports');
    const [year, setYear] = useState(currentYearValue);

    const changeHandler = (e: any) => {
        setReportType(e.target.value);
    };

    const handleYearSelect = (evt?: any) => {
        setYear(evt.target.value);
    };

    return (
        <>
            <div className='radioTitleSection'>
                <div className='row mx-0 gx-0'>
                    <div className='col-lg-12 col-xl-12 col-xxl-12'>
                        <div className="radioYear">
                            <div className="row mx-0">
                                <div className="col-md-2 pe-0">
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" name="SummaryReports" value="SummaryReports" id="SummaryReports" checked={reportType === "SummaryReports"} onChange={changeHandler} />
                                        <label className="form-check-label" htmlFor="SummaryReports">
                                            Summary Reports
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 pe-0">
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" name="AllTenders" value="AllTenders" id="AllTenders" checked={reportType === "AllTenders"} onChange={changeHandler} />
                                        <label className="form-check-label" htmlFor="AllTenders">
                                            Status of All Tenders
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 pe-0">
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" name="AwardedTenders" value="AwardedTenders" id="AwardedTenders" checked={reportType === "AwardedTenders"} onChange={changeHandler} />
                                        <label className="form-check-label" htmlFor="AwardedTenders">
                                            Status of Awarded Tenders
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 pe-0">
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" name="Pma" value="Pma" id="Pma" checked={reportType === "Pma"} onChange={changeHandler} />
                                        <label className="form-check-label" htmlFor="Pma">
                                            PMA
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 pe-0">
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" name="DetailedReport" value="DetailedReport" id="DetailedReport" checked={reportType === "DetailedReport"} onChange={changeHandler} />
                                        <label className="form-check-label" htmlFor="DetailedReport">
                                            Detailed Report
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <section className="financialForm">
                {/*<div className="fYearMonth">
                    <div className="row mx-0 align-items-center gx-3">
                        <div className='col-md-auto text-end pe-0'>
                            <label>FY</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="year" defaultValue={year} id="year" className="form-select" onChange={handleYearSelect} >
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                            </select>
                        </div>
                    </div>
                </div>*/}

               <div className="">
                   <div aria-hidden={reportType !== "SummaryReports"}><SummaryReports year={year} reportType={reportType} /></div>
                   <div aria-hidden={reportType !== "AllTenders"}><AllTendersReport year={year} reportType={reportType} /></div>
                   <div aria-hidden={reportType !== "AwardedTenders"}><AwardedTendersReport year={year} reportType={reportType} /></div>
                   <div aria-hidden={reportType !== "Pma"}>
                        <div className="alert alert-info text-center" role="alert">Not Applicable</div>
                    </div>
                   <div aria-hidden={reportType !== "DetailedReport"}>
                        <div className="alert alert-info text-center" role="alert">Not Applicable</div>
                   </div>
               </div>
            </section>
        </>
    );
};

const MisAwards = (props: any) => {
    return (
        <>
            <Header />
            <div className="mainWrap">
                {/*<section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            <li><Link to={"/rdss"} className="text-decoration-none">RDSS</Link></li>
                            <li>Award Module MIS Reports</li>
                        </ul>
                    </div>
                </section>*/}
                <InnerTemplate PageTitle="Award Module MIS Reports" />
                <section className="financialForm misNew">
                    <div className="container">
                        <div className="financialMainFrom">
                            <MisAwardReport />
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
}

export default MisAwards;