import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init,  multijsoninit } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import Header from "../../Layout/Default/Element/Header";
import Footer from "../../Layout/Default/Element/Footer";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { useStateMounted } from '../../Core/Hooks';
import { Alert } from 'react-bootstrap';
import FieldComponent from "../../FieldComponent";
import { Storage } from "../../Core/Services/StorageService";

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });
        setTimeout(() => {
            multijsoninit();
            init();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    return (
        <>
            <table className="table caption-top">
                <caption className="blankPara">Authorised Signatories</caption>
                <thead className='text-center'>
                    <th style={{ width: "50px" }}>S.No</th>
                    <th>Enable/Disable</th>
                    <th>Name of official</th>
                    <th>Designation</th>
                    <th> Specimen Signatures of authorized officials <br/>(Please upload only image formats. Do not link PDFs)</th>
                    <th>Authorised From (Date)</th>
                    {/* <th>Authorised Till (Date)</th> */}
                    <th>Type of Work</th>
                    <th>Grant No./Scheme Code</th>
                    {props.view != 'Y' ?
                        <th className='text-end pe-1' style={{ width: "50px" }}>
                            <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        </th>
                        : null
                    }

                </thead>
                <tbody>
                    {
                        block1Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td>{(block1SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            const options: any = { showTitle: false, extraId: '_' + key + '_' + fkey };
                                            const newField: any = field;
                                            if (field.type == 'file') {
                                                options.parentIndex = [(block1SrNo - 1)];
                                                newField.parent_type = ['multijson', 'multijson'];
                                                newField.isMultiFile = false;
                                            }
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent
                                                        options={options}
                                                        fieldName={field.key} field={newField} /></td>
                                            </>;
                                        })
                                    }

                                    {props.view != 'Y' ?
                                        <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                        : null
                                    }
                                </tr>
                            </>
                                : null;
                        })
                    }
                </tbody>
            </table>
        </>
    );
}
const AuthorisedSignatoryFormYearReports = (props?: any) => {
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useStateMounted<string>();
    const [apiurl, setApiurl] = useState<any>(null);
    const [fields, setFields] = useState<any[]>([]);
    const [getfddiscomhistory, setFdDiscomHistory] = useState<any[]>([]);
    const [year, setYear] = useState<any>(0);
    const [releasefundid, setReleaseFundId] = useState<any>(0);
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);
    const layout = useLayout();
    let layoutDataSubscribe: any;

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setYear(location.state.year);
                setReleaseFundId(location.state.release_fund_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [releasefundid, year]);

    const navtab = () => {
        setPageLink('/fund-disbursal');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { release_fund_id: releasefundid, data: getdocumentation, year: year, is_yearly_back: '1' } });
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (roleid == 2 || roleid == 3 || roleid == 4) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", releasefundid);
            myFormData.append("year", year);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data-yearly', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        navigate('/fund-disbursal', { state: { release_fund_id: releasefundid, data: getdocumentation, year: year, is_yearly_back: '1' } });
                    }
                });
        }
    }

    const getFields = () => {
        (async () => {
            setIsLoaded(false);
            setError('');
            setFields([]);

            if (roleid && discomid && year) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 17,
                    discom_user_id: discomuserid,
                    year: year,
                    module: 'fd_yearly'
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdyearfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(false);
                                setFields(res.fields);
                                setSubmittedStatus(res.submittedStatus);
                                setView(res.view);
                                layout.set({ fieldsresult: res });
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [roleid, discomid, year]);

    return (
        <>
            <Header />
            <div className="mainWrap">
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Yearly Report Submission</Link></li>
                            <li>Authorised Signatory</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <form noValidate onSubmit={onSubmit} ref={formData} >
                                        <div>
                                            {error && (
                                                <>
                                                    <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                                                </>
                                            )}

                                            {
                                                ((!error) && fields?.length > 0) && (
                                                    <>
                                                        <div className="radioYear pe-md-4 py-3 h-auto">
                                                            <div className="row justify-content-center align-items-center m-md-0">
                                                                <div className="col-sm-4 col-lg-6 col-xl-4">
                                                                    <div className="form-check ps-0">
                                                                        Discom
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-2 col-lg-2 col-xl-4">
                                                                    <div className="form-check ps-0">
                                                                        State
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-lg-6 col-xl-4">
                                                                    <div className="form-check ps-0">
                                                                        Date of signing authorization letter
                                                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-responsive RevenueDetailsTable mt-4">
                                                            <Block1 field={fields[2]} view={view} />
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div className="formBtnSection justify-content-between">
                                                <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab()}>Back</Link>
                                                {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4)) ?
                                                    <button className="btnDraft" type="submit">Save & Back</button>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    )
}
export default AuthorisedSignatoryFormYearReports;
