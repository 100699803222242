import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import { FiCheck, FiX, FiExternalLink } from 'react-icons/fi'
import swal from 'sweetalert';
import FdDiscomHistory from "./FdDiscomHistory";
import { Http } from "../../Core/Services/HttpService";
import MyTooltip from "./MyTooltip";
import FieldComponent from "../../FieldComponent";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { useStateMounted } from '../../Core/Hooks';
import { Storage } from "../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export const YearlyReportSubmission = () => {
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getpagelinkid, setPageLinkId] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [getdocumentationids, setDocumentationIds] = useState<any>(null);
    const [getButtonStatus, setButtonStatus] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [getsubmittedstatus, setSubmittedStatus] = useState<any>(0);
    const [getfddiscomhistory, setSubmittedHistory] = useState<any[]>([]);
    const [fields, setFields] = useState<any[]>([]);
    const [type_of_entity, setTypeOfEntity] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [year, setYear] = useState<any>('2022');
    const [dropDownYearValue, setDropDownYearValue] = useState<any>('2021-2022');
    const location = useLocation();
    let navigate = useNavigate();

    const selectYearValue = (evt?: any) => {
        if (evt.target.value != 0 || evt.target.value != '') {
            const year_array = evt.target.value.split('-');
            setYear(year_array[1]);
            setDropDownYearValue(evt.target.value);
        }
    };

    const gottopage = (gottopage: any, id: any) => {
        setPageLink(gottopage);
        setPageLinkId(id);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { year: year, release_fund_id: getpagelinkid } });
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setTypeOfEntity(Storage.get('type_of_entity'));

        let currentYearValue = new Date().getFullYear();
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setYear(currentYearValue);
        let currentYear = (currentYearValue-1) + '-' + currentYearValue;
        setDropDownYearValue(currentYear);
    }, [roleid]);

    const getFields = () => {
        (async () => {
            setError('');
            setFields([]);
            if (year && discomid && year) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 1001,
                    year: year,
                    discom_user_id: discomuserid
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                setIsLoading(true);
                await Http.get(`/apiv1/fdyearfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoading(false);
                                setFields(res.fields);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res._resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoading(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [roleid, discomid, year]);

    const getYearlyReportSubmission = () => {
        (async () => {
            setIsLoading(true);
            setError('');
            if (discomid && discomuserid && roleid && year) {
                const params = {
                    discom_id: discomid,
                    discom_user_id: discomuserid,
                    roleid: roleid,
                    year: year
                };

                await Http.get(`/apiv1/getfddiscomsubmitedapplicationyear`, { params })
                    .then((res) => {
                        if (res._resultflag && res.data) {
                            if (res.data) {
                                if (!res.data.length) setError("No data found.");
                                getDocumentation(res.data);
                                setYear(res.year);
                                setDocumentationIds(res.documentation_ids);
                                setButtonStatus(res.button_status);
                                setSubmittedStatus(res.submitted_status);
                                setSubmittedHistory(res.funddisbursal_history);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res._resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError('System error occurred!! please try again.');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getYearlyReportSubmission();
        })();
    }, [discomid, roleid, discomuserid, year]);

    const submittoreviewer = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);
        myFormData.append("year", year);

        swal({
            title: "Are you sure to submit this form?",
            text: "After submit, You will not able to edit this form.",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/fund-disbursal-forward-yearly', myFormData, config)
                .then((result) => {
                    if (result._resultflag == 0) {
                        setIsLoading(false);
                        swal("Warning", result.message, "info");
                    } else {
                        swal("Fund Disbursal Form", "Data has been forwarded", "success");
                        setIsLoading(false);
                        setRefresh(true);
                        getDocumentation(result.data);
                        setYear(result.year);
                        setDocumentationIds(result.documentation_ids);
                        setButtonStatus(result.button_status);
                        setSubmittedStatus(result.submitted_status);
                        setSubmittedHistory(result.funddisbursal_history);
                    }
                });
            } else {
                setIsLoading(false);
                // swal("Cancelled", "", "error");
            }
        });
    }

    const submittobackward = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('discom_id', discomid);
        myFormData.append("year", year);
        myFormData.append('form_step_no', form_step_no);

        swal({
            title: "Are your sure want to return this form?",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/fund-disbursal-backward-yearly', myFormData, config)
                    .then((result) => {
                        if (result._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", result.message, "info");
                        } else {
                            swal("Fund Disbursal Form", "The form has been returned for correction", "success");
                            setIsLoading(false);
                            setRefresh(true);
                            getDocumentation(result.data);
                            setYear(result.year);
                            setDocumentationIds(result.documentation_ids);
                            setButtonStatus(result.button_status);
                            setSubmittedStatus(result.submitted_status);
                            setSubmittedHistory(result.funddisbursal_history);
                        }
                    });
            } else {
                setIsLoading(false);
            }
        });
    }

    const saveDraft = () => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('discom_id', discomid);
        myFormData.append("year", year);

        Http.post('/apiv1/fund-release-file-upload-yearly', myFormData, config)
            .then((result) => {
                if (result._resultflag == 0) {
                    setIsLoading(false);
                    setRefresh(true);
                    swal("Warning", result.message, "info");
                } else {
                    setIsLoading(false);
                    setRefresh(true);
                    swal("Fund Disbursal Form", result.message, "success");
                    getDocumentation(result.release_funds_documentation);
                    setButtonStatus(result.button_status);
                    setSubmittedStatus(result.submitted_status);
                    setTimeout(() => {
                    }, 2000);
                }
            });
    }

    return (
        <>
            <section className="financialForm">
                <div className="financialMainFrom">
                    <div className="tab-content border-0 p-0">
                        <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                            <div className='fYearMonth setPosition'>
                                <div className='row justify-content-end align-items-center gx-2'>
                                    <div className='col-md-6 text-end'>
                                        <label>Select Year (FY)</label>
                                    </div>
                                    <div className='col-md-4'>
                                        <select className="form-select selectYear" onChange={selectYearValue} aria-label="select year" value={dropDownYearValue}>
                                            <option value={"2021-2022"}>2021-2022</option>
                                            <option value={"2022-2023"}>2022-2023</option>
                                            <option value={"2023-2024"}>2023-2024</option>
                                            <option value={"2024-2025"}>2024-2025</option>
                                            <option value={"2025-2026"}>2025-2026</option>
                                            <option value={"2026-2027"}>2026-2027</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <form noValidate ref={formData} >
                                <div className="table-responsive RevenueDetailsTable mt-4">
                                    <table className="table caption-top">
                                        <caption className='text-center'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div></div>
                                                <div>Yearly Report Submission</div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="w-25">
                                                        <MyTooltip>
                                                            <ul>
                                                                <li>It is mandatory for the DISCOM to upload the below mentioned documents even if the
                                                                    DISOCM has not applied for any fund disbursement throughout the financial year.</li>
                                                            </ul>
                                                        </MyTooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </caption>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }}>S. No.</th>
                                                <th style={{ width: "20%" }}>Form Title</th>
                                                <th style={{ width: "20%" }}>Target Date</th>
                                                <th style={{ width: "20%" }}>Submission Date</th>
                                                <th style={{ width: "5%" }}>Status</th>
                                                <th style={{ width: "5%" }}>Link</th>
                                                <th style={{ width: "20%" }}>Supporting documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(getdocumentation.length > 0) ?
                                                <>
                                                    {
                                                        getdocumentation.map((item: any, key: number) => {
                                                            return <>
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>{item.title}</td>
                                                                    <td className="text-cenetr">
                                                                        {(item.id == 2) ?
                                                                            <>
                                                                                <> 30 June {year}</>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {item.id != 3 ?
                                                                                    <> 30 April {year}</>
                                                                                    :
                                                                                    <>-</>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>{item.created_at == null ?
                                                                        <>N/A</>
                                                                        :
                                                                        <>{item.created_at}</>
                                                                    }</td>
                                                                    <td className="text-cenetr">
                                                                        {item.submitted_status == "1" ?
                                                                            <> <FiCheck className="p-0 mb-0 h5 text-success" /> </>
                                                                            :
                                                                            <> <FiX className="p-0 mb-0 h5 text-danger" /> </>
                                                                        }
                                                                    </td>
                                                                    <td className="text-cenetr">
                                                                        {item.link != "" ?
                                                                            <Link className='text-primary' to="" onClick={() => gottopage(item.link, item.id)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                            : null
                                                                        }
                                                                    </td>
                                                                    <td className="text-cenetr">
                                                                        {item.link == "" ?
                                                                            <>
                                                                                <FieldComponent options={{ showTitle: false }} fieldName={fields[item.id] && fields[item.id].key ? fields[item.id].key : ''} field={fields[item.id] && fields[item.id] ? fields[item.id] : ''} />
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {
                                                                            [1, 3, 4, 5].includes(item.id) && item.submitted_status == "1" ?
                                                                                item.id == 3 ?  // Authorization
                                                                                    <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-year-pdf-single-forms/?form_id=17&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                    :
                                                                                    item.id == 5 ? // Physical Progress Form	
                                                                                        <>
                                                                                            <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-year-pdf-single-forms/?form_id=21&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid + '&fd_link_pdf=1'}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                            {/* <a href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pp-export-excel/?form_id=21&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' +year+ '&discomuserid=' +discomuserid+ '&module=yearly_report_submission'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary"/></a> */}
                                                                                        </>
                                                                                    :
                                                                                    item.id == 4 ?  // Financial Progress	
                                                                                        <>
                                                                                            <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-year-pdf-single-forms/?form_id=39&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                            <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-fp-export-excel/?form_id=39&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid + '&module=yearly_report_submission'}><AiOutlineFileExcel className="pl-3 mb-0 h5 ms-4 text-primary" /></a>
                                                                                        </>
                                                                                    :
                                                                                    item.id == 1 && type_of_entity == "2" ? // Utilisation Forms	
                                                                                            <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-year-pdf-single-forms/?form_id=23&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                    :
                                                                                    item.id == 1 && type_of_entity != "2" ?  // Utilisation Forms	
                                                                                        <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-year-pdf-single-forms/?form_id=24&roleid=' + roleid + '&discom_id=' + discomid + '&type=export&year=' + year + '&discomuserid=' + discomuserid}><AiOutlineFilePdf className="pl-3 mb-0 h5 ms-4 text-warning" /></a>
                                                                                    : null
                                                                                :
                                                                                null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        })
                                                    }
                                                </>
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {(getButtonStatus == "active" && getsubmittedstatus == "1")  ?
                                    <>
                                        <div>
                                            <div className="form-group mb-4">
                                                <label className="d-block" htmlFor="remarks">Remarks</label>
                                                <textarea className="w-100" id="remarks" name="remarks"></textarea>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                <FdDiscomHistory FdHistoryBlock={getfddiscomhistory} />
                                <div className="formBtnSection justify-content-center">
                                    {getsubmittedstatus == "1" && (roleid == 2 || roleid == 3 || roleid == 4) ?
                                        <Link to="#" className="btnDraft" onClick={() => saveDraft()} >Save Draft</Link>
                                        : null
                                    }

                                    {
                                        getButtonStatus == "active" && getsubmittedstatus == "1"
                                            ?
                                            <>
                                                {roleid != 2 ?
                                                    <>
                                                        <Link to="#" className="btnCancle" onClick={() => submittobackward(1)} >Return</Link>
                                                    </>
                                                    : null
                                                }

                                                <Link to="#" className="btnCancle" onClick={() => submittoreviewer(1)} >
                                                    {roleid == 2 ? "Submit to Reviewer" : null}
                                                    {roleid == 3 ? "Submit to Approver" : null}
                                                    {roleid == 4 ? "Submit to Nodal Agency" : null}
                                                    {roleid == 5 ? "Submit to Approver" : null}
                                                    {roleid == 6 ? "Approve" : null}
                                                </Link>

                                            </>
                                            : null
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {isLoading ? <LoadingSpinner /> : null}
            </>
        </>
    );
};
export default YearlyReportSubmission;