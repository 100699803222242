import React, { useEffect, useRef, useState } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "../css/style.scss";
import Guidelines from "../Components/RdssTabs/Guidelines";
import SchemeOverview from "../Components/RdssTabs/SchemeOverview";
import ImportantDocuments from "../Components/RdssTabs/ImportantDocuments";
import TPQMA from "../Components/RdssTabs/TPQMA";
import DocumentCentre from "../Components/RdssTabs/DocumentCentre";
import AuditHistory from "../Components/RdssTabs/AuditHistory";
import Notifications from '../Components/Notifications/Notifications';
import useCurrentWidth from "../Components/Accordion/useDebounce";
import Accordion from "../Components/Accordion/Accordion";
import NodalAction from '../Components/RdssTabs/NodalAction';
import QualityMonitoring from "../Components/RdssTabs/QualityMonitoringNodalView";
import FundReleased from "../Components/FundReleased/IndexPage";
import { Navigate,useLocation } from "react-router-dom";
import CustomMisReports from '../Reports/CustomMisReports';
import MisReports from '../Reports/MisReports/index';

const NodalView = () => {

  const location = useLocation();
  const [isOpenQmTab, setOpenQmTab] = useState(false);
  const [isOpenActionTab, setOpenActionTab] = useState(false);
  const [isOpenFdTab, setOpenFdTab] = useState(false);
  const [isOpenMisReportsTab, setOpenMisReportsTabb] = useState(false);

  useEffect(() => {
    if(location.hash == '#QualityMonitoring'){
      setOpenQmTab(true);
      setOpenActionTab(false);
      setOpenFdTab(false);
      setOpenMisReportsTabb(false);
    }else if(location.hash == '#FundReleased'){
      setOpenFdTab(true);
      setOpenQmTab(false);
      setOpenActionTab(false);
      setOpenMisReportsTabb(false);
    }else if(location.hash == '#MISReports'){
      setOpenFdTab(false);
      setOpenQmTab(false);
      setOpenActionTab(false);
      setOpenMisReportsTabb(true);
    }else{
      setOpenActionTab(true);
      setOpenQmTab(false);
      setOpenFdTab(false);
      setOpenMisReportsTabb(false);
    }
}, [location.hash]);

  const accordionPanels = [
    {
      id: "Dashboard",
      heading: "Dashboard",
      content: (
        <p></p>
      ),
      link: "/dashboard"
    },
    {
      id: "Guidelines",
      heading: "Guidelines & SBD",
      content: (
        <Guidelines/>
      )
    },
    {
      id: "ImportantDocuments",
      heading: "Important documents",
      content: (
        <ImportantDocuments/>
      )
    },
    {
      id: "SchemeOverview",      
      heading: "Scheme Overview",
      content: (
        <SchemeOverview/>
      )
    },
    {
      id: "ActionCentre",      
      open: isOpenActionTab,
      heading: "Action Centre",
      content: (
        <>
        <NodalAction />
        </>
      )
    },
    {
      id: "QualityMonitoring",
      open: isOpenQmTab,
      heading: "Quality Monitoring",
      content: (
        <>
        <QualityMonitoring />
        </>
      ),
      link: "/rdss/quality-monitoring"
    },
    {
      id: "FundReleased",
      heading: "Fund Released",
      open: isOpenFdTab,
      content: (
        <>
        <FundReleased />
        </>
      )
    },
    /*
    {
      id: "/miscustom-report",
      heading: "MIS Custom Reports",
      content: (
       <p></p>
      ),
      link: "/mis-custom-reports"
    },
    */
    {
      id: "DMS Checklist",
      heading: "DMS Checklist",
      content: (
          <p>&nbsp;</p>
      ),
      link: "/dms-checklist"
    },
    {
      id: "MISReports",
      open: isOpenMisReportsTab,
      heading: "MIS Reports",
      content: (
        <MisReports />
      )
    },
    {
      id: "DocumentCentre",
      heading: "Document Centre",
      content: (
        <DocumentCentre/>
      )
    },
    {
      id: "Notifications",
      heading: "Notifications",
      content: (
        <Notifications module={'RDSS'}/>
      )
    },
  ];

  const mobileBreakpoint = 769;
  const isMobile = useCurrentWidth() < mobileBreakpoint;

  return (
    <>
      <Header />
      <InnerTemplate PageTitle="Nodal Agency View" />
      <div className="mainWrap">
        <div className="container">
          <div className="aboutAccordioin">
            <Accordion asTabs={!isMobile} onlyOneOpenAccordion={true} panels={accordionPanels} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NodalView;