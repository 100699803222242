import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';

/* Route */
const routes: RouteProps[] = [
  {
    path: '/about',
    element: lazy(() => import('../Pages/About')),
    index: true,
    private: true
  },
  {
    path: '/tm',
    element: lazy(() => import('../Pages/TrainingManagement')),
    index: true,
    private: true
  },
  {
    path: '/tm/tc',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender')),
    index: true,
    private: true
  },
  {
    path: '/tm/tc/input-form',
    element: lazy(() => import('../Pages/TrainingManagement/TrainingCalender/InputForm')),
    index: true,
    private: true
  }
];

export default routes;