import React, { useEffect, useState } from "react";
import { Http } from '../../Core/Services/HttpService';
import Select from "react-select";
import ExcelIcn from '../../images/excel.svg';
import PdfIcn from '../../images/pdf.svg';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Storage } from "../../Core/Services/StorageService";

const PvtgVvpReports = (props: any) => {
    const roleids = [
        process.env.REACT_APP_NDR,
        process.env.REACT_APP_NDA
    ];
    let roleid: any = Storage.get('roleid') ? Storage.get('roleid') : 0;
    let discom_id: any = Storage.get('discom_id') ? Storage.get('discom_id') : 0;
    const [level, setLevel] = useState("1");

    const [reportType, setReportType] = useState<any>('pvtg');
    const [discomList, setDiscomList] = useState([{ 'id': 0, 'discom_name': 'All' }]);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [selectedMultiDiscom, setSelectedMultiDiscom] = useState<any>("");
    const [getdiscom, setGetDiscom] = useState<any>(true);
    const [levels, setLevels] = useState<any>([]);

    const [reportCols, setReportCols] = useState<any>(null);
    const [drildown, setDrildown] = useState(false);
    const [reportData, setReportData] = useState<any>([]);

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    useEffect(() => {
        const _levels = [
            { value: '1', label: 'Habitation Level' },
            { value: '2', label: 'Household Level' },
        ];
        if (reportType === 'pvtg') {
            _levels.push({ value: '3', label: 'Discom wise Summary' });
            _levels.push({ value: '4', label: 'District wise Summary' });
        }
        setLevels(_levels);
        handleLevelSelect('1');
    }, [reportType]);

    const handleLevelSelect = (_level?: any) => {
        clearparams();
        setCurrentPage(0);
        setLevel(_level);
        let discoms: any = document.getElementById('discoms');
        if (discoms) discoms.classList.remove('d-none');
        if (_level == '3' || _level == '4')
            if (discoms) discoms.classList.add('d-none');
    };

    const handleDiscomSelect = (selectedDiscom: any) => {
        setCurrentPage(0);
        setSelectedMultiDiscom(selectedDiscom.map((item: any) => item.value).join(","));
        setSelectedDiscom(selectedDiscom);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const getPvtgVvpData = async (optParams?: {}) => {
        let discomid = roleids.includes(roleid.toString()) ? selectedMultiDiscom : discom_id;
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        let params = {
            reportType: reportType,
            level: level,
            discomid: discomid,
            page: curPage,
            pageSize: pageSize,
        };
        params = Object.assign(params, optParams);
        if (curPage >= 0) {
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/get-pvtgvvp-report', { params })
                .then((res) => {
                    setReportCols(res.cols);
                    setReportData(res.rows);
                    setTotalItems(res.totalRecord);
                    setPageCount(Math.ceil(res.totalRecord / pageSize));
                    setTotalPages(Math.ceil(res.totalRecord / pageSize));

                    let cPage = (!currentPage) ? 1 : currentPage;
                    setFirst((cPage - 1) * pageSize + 1);
                    setLast(Math.min(res.totalRecord, cPage * pageSize));

                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                    setDrildown(false);
                });
        }
    }

    const getReportData = async () => {
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/get-pvtgvvp-discoms')
            .then((res) => {
                setDiscomList(res.discoms);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (!drildown && footerspinner)
                    footerspinner.classList.add('d-none');
            });
    }

    useEffect(() => {
        if (getdiscom) {
            setGetDiscom(false);
            getReportData();
        }
    }, [getdiscom]);

    const handleRadioChange = (e: any) => {
        setReportType(e.target.value);
        clearparams();
    };

    const clearparams = () => {
        setSelectedDiscom([]);
        setSelectedMultiDiscom('');
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);
        setReportCols([]);
        setReportData([]);
    }

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt + "").replace(/[^\-.0-9]+/g, '');
        if (amt == '') amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>" + amt + "</mark>";
        }
        return amt;
    }

    useEffect(() => {
        if (currentPage != 0) {
            getPvtgVvpData();
        }
    }, [currentPage]);

    return (
        <>

            <section className="financialForm">
                <div className="fYearMonth">
                    <div className="row mx-0 align-items-center gx-3">
                        <div className='col-md-auto text-end pe-0'>
                            <label>Level</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="level" id="level" defaultValue={level} className="form-select" onChange={(e) => handleLevelSelect(e.target.value)} >
                                {levels.map((item: any, i: number) => <option key={i} value={item.value}>{item.label}</option>)}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="radioYear">
                    <div className="row align-items-center mx-0 float-start">
                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="pvtg" id="pvtg" checked={reportType === 'pvtg'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="pvtg">PVTG </label>
                            </div>
                        </div>

                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="vvp" id="vvp" checked={reportType === 'vvp'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="vvp">VVP </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    {roleids.includes(roleid.toString()) ?
                        <div id="discoms" className="col-md-3">
                            <div className="misSelectReportPosition lPosition">
                                <label htmlFor="discom" className="form-label">Discom</label>
                                <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom} isMulti />
                            </div>
                        </div>
                        : null
                    }
                    <div className="col-md-{ roleids.includes(roleid.toString()) ? '9' : '12' }">
                        <div className="row justify-content-between align-items-center misBottomBtn">
                            <div className="col-md-2 text-start mt-5">
                                <button className="bsSearchBtn mt-0" onClick={() => { setPageInput(1); setCurrentPage(1); getPvtgVvpData(); }}>Search</button>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-3 text-end mt-4">
                                <div className="dIcnBtn">
                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-pvtgvvp-report?reportType=' + reportType + '&discomid=' + (roleids.includes(roleid.toString()) ? selectedMultiDiscom : discom_id) + '&level=' + level + '&module=ls&task=excel'}><img src={ExcelIcn} /></a>
                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-pvtgvvp-report?reportType=' + reportType + '&discomid=' + (roleids.includes(roleid.toString()) ? selectedMultiDiscom : discom_id) + '&level=' + level + '&module=ls&task=pdf'}><img src={PdfIcn} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    {
                        totalItems ?
                            <div>Displaying {first} to {last} of {totalItems}</div>
                            :
                            null
                    }
                    <div className="mt-2 financialMainFrom misReportTable">
                        <div className="tab-content p-0 border-0">
                            <div className="table-responsive RevenueDetailsTable misreportTable">
                                <table className="table">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col" className="text-start">Sr. No</th>
                                            {reportCols ?
                                                <>
                                                    {
                                                        reportCols.map((reportcol: any, index: number) =>
                                                            (reportcol.display && reportcol.display == 'N') ? null : <th scope="col">{reportcol['title']}</th>
                                                        )
                                                    }
                                                </>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData ?
                                            reportData.map((reportData: any, index: number) => {
                                                return <tr>
                                                    <td>{(reportData.srNo)}</td>
                                                    {
                                                        reportCols.map((reportcol: any) =>
                                                            (reportcol.display && reportcol.display == 'N') ? null
                                                                :

                                                                <>
                                                                    <td className={"mis" + reportcol.key}>
                                                                        {
                                                                            reportcol.formatted == 'Y' && reportData[reportcol.key] != '' ?
                                                                                // toFixed(reportData[reportcol.key], 2)
                                                                                <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], reportcol.decimal ? reportcol.decimal : 2) }}></div>
                                                                                :
                                                                                <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                        }
                                                                    </td>
                                                                </>
                                                        )
                                                    }
                                                </tr>
                                            })
                                            :
                                            <tr>
                                                <td colSpan={10} className="text-center my-5">
                                                    <h5>No Record Found</h5>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {totalItems != 0 ?
                                <div className="mt-3">
                                    <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronDoubleLeft />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronCompactLeft />
                                                </button>
                                            </li>
                                            <li className="page-item col-1">
                                                <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                    <BsChevronCompactRight />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                    <BsChevronDoubleRight />
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="page-item text-center">
                                        <span>
                                            Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PvtgVvpReports;