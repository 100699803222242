import React, { useEffect, useState } from "react";
import { Http } from '../../Core/Services/HttpService';
import Select from "react-select";
import ExcelIcn from '../../images/excel.svg';
import PdfIcn from '../../images/pdf.svg';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const AssetWise = (props: any) => {
    let currentYearValue = new Date().getFullYear();
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const d = new Date();
    if (d.getMonth() > 2) {
        currentYearValue++;
    }
    let currentMonthValue = months[d.getMonth()];

    const [reportType, setReportType] = useState<any>('Allindia');
    const [discomList, setDiscomList] = useState([{ 'id': 0, 'discom_name': 'All' }]);
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [selectedMultiDiscom, setSelectedMultiDiscom] = useState<any>("");

    const [districtList, setDistrictList] = useState([{ 'id': 0, 'district_name': 'All' }]);
    const [selectedDistrict, setSelectedDistrict] = useState<any>(0);
    const [selectedMultiDistrict, setSelectedMultiDistrict] = useState<any>("");

    const [statesList, setStatesList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [selectedState, setSelectedState] = useState<any>(0);
    const [selectedMultiState, setSelectedMultiState] = useState<any>("");

    const [searchText, setSearchText] = useState<any>('');
    const [selectedMultiMonitoringParameters, setSelectedMultiMonitoringParameters] = useState<any>("");

    const [getstatesapiurl, setStatesApiurl] = useState<any>(null);
    const [reportCols, setReportCols] = useState<any>(null);
    const [drildown, setDrildown] = useState(false);
    const [reportData, setReportData] = useState<any>([]);
    const [month, setMonths] = useState(currentMonthValue);
    const [year, setYear] = useState(currentYearValue);
    const [level, setLevel] = useState("1");
    const [opttype, setOpttype] = useState("0");
    const [selectedMonitoringParameters, setSelectedMonitoringParameters] = useState<any>("");

    const [selectedMajorComponent, setSelectedMajorComponent] = useState<any>(0);
    const [selectedMultiMajorComponent, setSelectedMultiMajorComponent] = useState<any>("");
    const [smMajorComponents, setSmMajorComponents] = useState([{ 'id': 0, 'title': 'All' }]);
    const [getcomponentsapiurl, setComponentsApiurl] = useState<any>(null);

    const [columnsopts, setColumnsOpts] = useState<any>(
        [
            { value: "0", label: 'All' },
            { value: "1", label: "Sanctioned Cost" },
            { value: "2", label: "Award Cost" },
            { value: "3", label: "Sanctioned Items" },
            { value: "4", label: "Award Items" },
            { value: "5", label: "Surveyed Items" },
            { value: "6", label: "Installed" },
        ]
    );

    const [assets, setAssets] = useState<any>(
        [
            { value: "0", label: 'All' },
            { value: "1", label: "HT Line" },
            { value: "2", label: "LT Line" },
            { value: "3", label: "DTR" },
            { value: "4", label: "Substation" },
        ]
    );
    const [selectedAssets, setSelectedAssets] = useState<any>("");
    const [selectedMultiAssets, setSelectedMultiAssets] = useState<any>("");

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const searchParams = new URLSearchParams(window.location.search);
    const filterStateVal = searchParams.get('stateid');

    const handleMonitoringParametersSelect = (selectedMonitoringParameters: any) => {
        setCurrentPage(0);
        let multiMonitoringParameters = selectedMonitoringParameters.map((item: any) => item.value).join(",");
        setSelectedMultiMonitoringParameters(multiMonitoringParameters);
        setSelectedMonitoringParameters(selectedMonitoringParameters);
    };
    
    const handleAssetsSelect = (selectedAssets: any) => {
        setCurrentPage(0);
        setSelectedMultiAssets(selectedAssets.map((item: any) => item.value).join(","));
        setSelectedAssets(selectedAssets);
    };
    
    const handleMajorComponentSelect = (selectedMajorComponent: any) => {
        setCurrentPage(0);
        let multiSelectedMajorComponent = selectedMajorComponent.map((item: any) => item.value).join(",");
        setSelectedMultiMajorComponent(multiSelectedMajorComponent);
        setSelectedMajorComponent(selectedMajorComponent);
    };

    const handleYearSelect = (evt?: any) => {
        setCurrentPage(0);
        setYear(evt.target.value);
    };

    const handleLevelSelect = (evt?: any) => {
        clearparams();
        setCurrentPage(0);
        setLevel(evt.target.value);
        let obj1 = document.getElementById('divassets');
        let obj2 = document.getElementById('divmajorcomponent');
        let obj3 = document.getElementById('divtype');
        obj1?.classList.add('d-none');
        obj2?.classList.add('d-none');
        obj3?.classList.add('d-none');
        if ( evt.target.value == '1' )
            obj1?.classList.remove('d-none');
        if ( evt.target.value == '2' )
        {
            obj1?.classList.remove('d-none');
            obj2?.classList.remove('d-none');
        }
        if ( evt.target.value == '3' )
        {
            obj1?.classList.remove('d-none');
            obj2?.classList.remove('d-none');
            obj3?.classList.remove('d-none');
        }
    };

    const handleTypeSelect = (evt?: any) => {
        setCurrentPage(0);
        setOpttype(evt.target.value);
    };

    const handleMonthSelect = (evt?: any) => {
        setCurrentPage(0);
        setMonths(evt.target.value);
    };

    const handleDiscomSelect = (selectedDiscom: any) => {
        setCurrentPage(0);
        setSelectedMultiDiscom(selectedDiscom.map((item: any) => item.value).join(","));
        setSelectedDiscom(selectedDiscom);
    };

    const handleDistrictSelect = (selectedDistrict: any) => {
        setCurrentPage(0);
        setSelectedMultiDistrict(selectedDistrict.map((item: any) => item.value).join(","));
        setSelectedDistrict(selectedDistrict);
    };

    const handleStateSelect = (SelectedState: any) => {
        setCurrentPage(0);
        if (reportType == 'DisStates')
            setSelectedMultiState(SelectedState.map((item: any) => item.value).join(","));
        else
            setSelectedMultiState(SelectedState.value);
        setSelectedState(SelectedState);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }

    const handleSearchEnter = (event: any) => {
        if (event.key === 'Enter') {
            getSanctionLetterData(searchText);
        }
    }

    const getSanctionLetterData = async (search: any, optParams?: {}) => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        let params = {
            searchText: searchText,
            reportType: reportType,
            stateid: selectedMultiState,
            discomid: selectedMultiDiscom,
            districtid: selectedMultiDistrict,
            majorcomponents: selectedMultiMajorComponent,
            opttype: opttype,
            monitoringPar: selectedMultiMonitoringParameters,
            year: year,
            month: month,
            level: level,
            assets: selectedMultiAssets,
            page: curPage,
            pageSize: pageSize,
        };
        params = Object.assign(params, optParams);
        if (curPage >= 0) {
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/get-assetwise-report', { params })
                .then((res) => {
                    setReportCols(res.cols);
                    setReportData(res.rows);
                    setTotalItems(res.totalRecord);
                    setPageCount(Math.ceil(res.totalRecord / pageSize));
                    setTotalPages(Math.ceil(res.totalRecord / pageSize));

                    let cPage = (!currentPage) ? 1 : currentPage;
                    setFirst((cPage - 1) * pageSize + 1);
                    setLast(Math.min(res.totalRecord, cPage * pageSize));

                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                    setDrildown(false);
                });
        }
    }

    useEffect(() => {
        if (currentPage != 0) {
            getSanctionLetterData(searchText);
        }
    }, [currentPage]);

    useEffect(() => {
        if (props.filter) {
            setSearchText(props.filter);
            getSanctionLetterData(props.filter);
        }
    }, [props.filter, filterStateVal]);

    const drilDown = (col: any) => {
        setDrildown(true);
        setCurrentPage(0);
        if (reportType == 'DisStates') {
            setReportType('Discom');
            setSelectedMultiState(col.id);
            let SelectedState = { value: col.id, label: col.title1 };
            setSelectedState(SelectedState);
            const optParams = {
                reportType: 'Discom',
                stateid: col.id,
                page: 0,
            };
            getSanctionLetterData(searchText, optParams);
        }
        if (reportType == 'Discom') {
            setReportType('Districts');

            setSelectedMultiState(col.state_id);
            let SelectedState = { value: col.state_id, label: col.state_title };
            setSelectedState(SelectedState);

            setTimeout(() => {
                let SelectedDiscom = { value: col.id, label: col.title2 };
                setSelectedMultiDiscom(col.id);
                setSelectedDiscom(SelectedDiscom);
            }, 1000);

            const optParams = {
                reportType: 'Districts',
                stateid: col.state_id,
                discomid: col.id,
                page: 0,
            };
            getSanctionLetterData(searchText, optParams);
        }
        // console.log(reportType, col);
    }

    const getReportData = async () => {
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/mis-discom?state_id=' + selectedState.value + '&module=ls')
            .then((res) => {
                setDiscomList(res.discoms);
                setDistrictList([]);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (!drildown && footerspinner)
                    footerspinner.classList.add('d-none');
            });
    }

    useEffect(() => {
        getReportData();
    }, [selectedState.value])

    const handleRadioChange = (e: any) => {
        setReportType(e.target.value);
        clearparams();
    };

    const clearparams = () => {
        setSearchText('');
        setSelectedState(0);
        setSelectedMultiState('');
        setSelectedDiscom([]);
        setSelectedMultiDiscom('');
        setSelectedDistrict([]);
        setSelectedMultiDistrict("");
        setSelectedAssets([]);
        setSelectedMultiAssets('');
        setSelectedMajorComponent([]);
        setSelectedMultiMajorComponent('');
        setSelectedMonitoringParameters([]);
        setSelectedMultiMonitoringParameters('');
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);
        setReportCols([]);
        setReportData([]);
    }

    const getDistrict = async () => {
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/mis-district?stateid='+selectedState.value+'&discomid=' + selectedMultiDiscom)
            .then((res) => {
                setDistrictList(res.districtsList);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                if (!drildown && footerspinner)
                    footerspinner.classList.add('d-none');
            });
    }

    useEffect(() => {
        getDistrict();
    }, [selectedMultiDiscom]);


    const getStates = () => {
        (async () => {
            if (getstatesapiurl) {
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner) footerspinner.classList.remove('d-none');
                await Http.get(getstatesapiurl)
                    .then((res) => {
                        setStatesList(res.statesList);
                        setDiscomList([]);
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        if (!drildown)
                            footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        if (reportType == 'Discom' || reportType == 'DisStates' || reportType == 'Districts') {
            setStatesApiurl("/apiv1/mis-states");
        }
    }, [getstatesapiurl, reportType]);

    useEffect(() => {
        (async () => {
            getStates();
        })();
    }, [getstatesapiurl, reportType]);

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt + "").replace(/[^\-.0-9]+/g, '');
        if (amt == '') amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>" + amt + "</mark>";
        }
        return amt;
    }

    useEffect(() => {
        if (reportType == 'Discom') {
            setDiscomList([]);
        }
        if (reportType == 'Districts') {
            setDistrictList([]);
            setDiscomList([]);
        }
    }, [reportType]);

    useEffect(() => {
        setComponentsApiurl('/apiv1/mis-major-components?module=ls');
    }, [getcomponentsapiurl, reportType]);

    const getMajorComponents = () => {
        (async () => {
            if (getcomponentsapiurl) {
                await Http.get(getcomponentsapiurl+'&assets='+selectedMultiAssets)
                    .then((res) => {
                        setSmMajorComponents(res.lrmajorcomponent);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getMajorComponents();
        })();
    }, [getcomponentsapiurl, selectedMultiAssets]);

    return (
        <>

            <section className="financialForm">
                <div className="fYearMonth">
                    <div className="row mx-0 align-items-center gx-3">
                        <div className='col-md-auto text-end pe-0'>
                            <label>Level</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="level" id="level" defaultValue={level} className="form-select" onChange={handleLevelSelect} >
                                <option value="1">Assets</option>
                                <option value="2">Major Component</option>
                                <option value="3">Type (New / Augmented)</option>
                            </select>
                        </div>
                        <div className='col-md-auto text-end pe-0'>
                            <label>FY</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="year" id="year" defaultValue={year} className="form-select" onChange={handleYearSelect} >
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                            </select>
                        </div>
                        <div className='col-md-auto text-end pe-0'>
                            <label>Month</label>
                        </div>
                        <div className='col-md-auto ps-2'>
                            <select name="month" id="month" defaultValue={month} className="form-select" onChange={handleMonthSelect} >
                                <option value="apr">Apr</option>
                                <option value="may">May</option>
                                <option value="jun">Jun</option>
                                <option value="jul">Jul</option>
                                <option value="aug">Aug</option>
                                <option value="sep">Sep</option>
                                <option value="oct">Oct</option>
                                <option value="nov">Nov</option>
                                <option value="dec">Dec</option>
                                <option value="jan">Jan</option>
                                <option value="feb">Feb</option>
                                <option value="mar">Mar</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="radioYear">
                    <div className="row align-items-center mx-0 float-start">
                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="Allindia" id="AllindiaLs" checked={reportType === 'Allindia'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="AllindiaLs">All India </label>
                            </div>
                        </div>

                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="DisStates" id="StatesLs" checked={reportType === 'DisStates'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="StatesLs">State </label>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="Discom" id="DiscomLs" checked={reportType === 'Discom'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="DiscomLs">Discom</label>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="Reoption" value="Districts" id="DistrictsLs" checked={reportType === 'Districts'} onChange={handleRadioChange} />
                                <label className="form-check-label" htmlFor="DistrictsLs">Districts / Projects</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    {reportType != 'Allindia' ?
                        <>
                            {reportType == 'Discom' || reportType == 'DisStates' || reportType == 'Districts'
                                ?
                                <div className="col-md-3">
                                    <div className="misSelectReportPosition lPostion">
                                        <label htmlFor="states" className="form-label">States</label>
                                        {
                                            reportType == 'DisStates' ?
                                                <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} isMulti />
                                                :
                                                <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} />
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {reportType == 'Discom' || reportType == 'Districts'
                                ?
                                <div className="col-md-3">
                                    <div className="misSelectReportPosition lPosition">
                                        <label htmlFor="discom" className="form-label">Discom</label>
                                        <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom} isMulti />
                                    </div>
                                </div>
                                :
                                null
                            }

                            {reportType == 'Districts'
                                ?
                                <div className="col-md-3">
                                    <div className="misSelectReportPosition lPosition">
                                        <label htmlFor="districts" className="form-label">Districts / Projects</label>
                                        <Select id="districts" className="misReportSelect" aria-label="Districts" onChange={handleDistrictSelect} isSearchable={true} options={districtList} value={selectedDistrict} isMulti />
                                    </div>
                                </div>
                                : null
                            }
                        </>
                        : null
                    }
                    <div id="divassets" className="col-md-3">
                        <div className="misSelectReportPosition lPosition">
                            <label htmlFor="assets" className="form-label">Assets</label>
                            <Select id="assets" className="misReportSelect" aria-label="Assets" onChange={handleAssetsSelect} isSearchable={true} options={assets} value={selectedAssets} isMulti />
                        </div>
                    </div>
                    <div id="divmajorcomponent" className="col-md-3 d-none">
                        <div className="misSelectReportPosition sPosition">
                            <label htmlFor="majorcomponent" className="form-label">Major Components</label>
                            <Select id="majorcomponent" className="misReportSelect" aria-label="Major Components" onChange={handleMajorComponentSelect} isSearchable={true} options={smMajorComponents} value={selectedMajorComponent} isMulti />
                        </div>
                    </div>
                    <div id="divtype" className="col-md-3 d-none">
                        <div className="misSelectReportPosition sPosition">
                            <label htmlFor="opttype" className="form-label">Type</label>
                            <select name="opttype" id="opttype" defaultValue={opttype} className="form-select" onChange={handleTypeSelect} >
                                <option value="0">All</option>
                                <option value="1">New</option>
                                <option value="2">Augmented</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="misSelectReportPosition text-start sPosition">
                            <label htmlFor="monitoringparameters" className="form-label">Column</label>
                            <Select id="monitoringparameters" className="misReportSelect" aria-label="Select Column" onChange={handleMonitoringParametersSelect} isSearchable={true} options={columnsopts} value={selectedMonitoringParameters} isMulti />
                        </div>
                    </div>
                    <div className="col-md-12 text-center">
                        <div className="row justify-content-between align-items-center misBottomBtn">
                            <div className="col-md-3">
                                <input type="text" className="form-control" onChange={handleSearchTextChange} value={searchText} placeholder="Search" onKeyDown={handleSearchEnter} style={{ height: 38 }} />
                            </div>
                            <div className="col-md-2 text-start">
                                <button className="bsSearchBtn mt-0" onClick={() => { setPageInput(1); setCurrentPage(1); getSanctionLetterData(searchText); }}>Search</button>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-3 text-end">
                                <div className="dIcnBtn">
                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-assetwise-report?searchText=' + searchText + '&reportType=' + reportType + '&stateid=' + selectedMultiState + '&districtid=' + selectedMultiDistrict + '&discomid=' + selectedMultiDiscom + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&level=' + level + '&assets=' + selectedMultiAssets + '&majorcomponents=' + selectedMultiMajorComponent + '&module=ls&task=excel'}><img src={ExcelIcn} /></a>
                                    <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-assetwise-report?searchText=' + searchText + '&reportType=' + reportType + '&stateid=' + selectedMultiState + '&districtid=' + selectedMultiDistrict + '&discomid=' + selectedMultiDiscom + '&monitoringPar=' + selectedMultiMonitoringParameters + '&year=' + year + '&month=' + month + '&level=' + level + '&assets=' + selectedMultiAssets + '&majorcomponents=' + selectedMultiMajorComponent + '&module=ls&task=pdf'}><img src={PdfIcn} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    {
                        totalItems ?
                            <div>Displaying {first} to {last} of {totalItems}</div>
                            :
                            null
                    }
                    <div className="mt-2 financialMainFrom misReportTable">
                        <div className="tab-content p-0 border-0">
                            <div className="table-responsive RevenueDetailsTable misreportTable">
                                <table className="table">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col" className="text-start">Sr. No</th>
                                            {reportCols ?
                                                <>
                                                    {
                                                        reportCols.map((reportcol: any, index: number) =>
                                                            (reportcol.display && reportcol.display == 'N') ? null : <th scope="col" dangerouslySetInnerHTML={{ __html: reportcol['title'] }}></th>
                                                        )
                                                    }
                                                </>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData ?
                                            reportData.map((reportData: any, index: number) => {
                                                return <tr>
                                                    <td>{(reportData.srNo)}</td>
                                                    {
                                                        reportCols.map((reportcol: any) =>
                                                            (reportcol.display && reportcol.display == 'N') ? null
                                                                : (
                                                                    (reportcol.drildown && reportcol.drildown == 'Y') ?
                                                                        <>
                                                                            <td className={"mis" + reportcol.key}>
                                                                                {
                                                                                    <Link to="#" onClick={() => drilDown(reportData)}>{
                                                                                        reportcol.formatted == 'Y' && reportData[reportcol.key] != '' ?
                                                                                            // toFixed(reportData[reportcol.key], 2)
                                                                                            <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], reportcol.decimal ? reportcol.decimal : 2) }}></div>
                                                                                            :
                                                                                            <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                                    }</Link>
                                                                                }
                                                                            </td>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <td className={"mis" + reportcol.key}>
                                                                                {
                                                                                    reportcol.formatted == 'Y' && reportData[reportcol.key] != '' ?
                                                                                        // toFixed(reportData[reportcol.key], 2)
                                                                                        <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], reportData['assets'] && (reportData['assets'] == 'DTR' || reportData['assets'] == 'Substation') && reportcol.decimal && reportcol.decimal == 0 ? 0 : 2) }}></div>
                                                                                        :
                                                                                        <div dangerouslySetInnerHTML={{ __html: reportData[reportcol.key] }}></div>
                                                                                }
                                                                            </td>
                                                                        </>
                                                                )
                                                        )
                                                    }
                                                </tr>
                                            })
                                            :
                                            <tr>
                                                <td colSpan={10} className="text-center my-5">
                                                    <h5>No Record Found</h5>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {totalItems != 0 ?
                                <div className="mt-3">
                                    <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronDoubleLeft />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                    <BsChevronCompactLeft />
                                                </button>
                                            </li>
                                            <li className="page-item col-1">
                                                <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                    <BsChevronCompactRight />
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                    <BsChevronDoubleRight />
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="page-item text-center">
                                        <span>
                                            Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AssetWise;