import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../RdssForms/rdssforms.scss";
import { useStateMounted } from '../../../Core/Hooks';
import FieldComponent from "../../../FieldComponent";
import { Alert } from 'react-bootstrap';
import { Storage } from "../../../Core/Services/StorageService";

const Block1 = (props: any) => {

  let block1SrNo = 0;
  const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
  const [block1Fields, setBlock1fields] = useState<any>([]);
  const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

  useEffect(() => {
    if (props.field) {
      setNewBlock1fields(props.field.addnew);
      const fields1 = props.field.childrens.map((items: any) => {
        return {
          fields: items,
          delete: false
        }
      })
      setBlock1fields(fields1);
    }
  }, [props.field]);

  useEffect(() => {
    if (block1Fields.length) {
      const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
      setHasBlock1FieldsDeletes(hasDelete);
      block1SrNo = 0;
    }
  }, [block1Fields]);

  const addItem = (block?: any) => {
    setBlock1fields((prev: any[]) => {
      return [...prev, { fields: newBlock1Fields, delete: false }];
    });

    setTimeout(() => {
      multijsoninit();
    }, 500);
  }

  const removeItem = (rindex: number, block?: any) => {
    setBlock1fields((prev: any[]) => {
      const newFields = prev.map((item, index) => {
        if (index === rindex) {
          item.delete = true;
        }
        return item;
      })
      return newFields;
    });

    setTimeout(() => {
      multijsoninit();
    }, 500);
  }

  return (
    <>
      <table className="table caption-top mt-4">
        <caption>Details of grants received, expenditure incurred and closing balances</caption>
        <thead className='text-center'>
          <tr>
            <th style={{ width: "50px" }}>S.No</th>
            <th style={{ minWidth: "100px", width: '150px' }}>Sanction No.</th>
            <th style={{ minWidth: "100px", width: '150px' }}>Date</th>
            <th style={{ minWidth: "100px", width: '150px' }}>Amount (INR)</th>
            {props.view != 'Y' ?
              <th className='text-end pe-1' style={{ width: "50px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
              </th>
              : null
            }
          </tr>
        </thead>
        <tbody>
          {
            block1Fields.map((item: any, key: number) => {
              return (item.delete === false) ? <>
                <tr key={key}>
                  <td className="text-center">{(block1SrNo++) + 1}</td>
                  {
                    item.fields.map((field: any, fkey: number) => {
                      return <>
                        <td key={key + '_' + fkey}>
                          <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                      </>;
                    })
                  }
                  {props.view != 'Y' ?
                    <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                    : null
                  }
                </tr>
              </>
                : null;
            })
          }
          <>
            {
              (block1Fields.length && props.field1)
                ? <>
                  <tr>
                    <td colSpan={3} className="text-center">Total</td>
                    <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                  </tr>
                </>
                : null
            }
          </>
        </tbody>
      </table>
    </>
  );
}

const GFR12AFForm = (props: any) => {
  const [roleid, setRoleid] = useState<any>(0);
  const [username, setUsername] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [getcomponentid, setComponentId] = useState<any>(0);
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [getaplicationtrackerid, setAplicationTrackerid] = useState<any>(0);
  const [error, setError] = useStateMounted<string>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [fields, setFields] = useState<any[]>([]);
  const [view, setView] = useState<any[]>([]);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    setUsername(Storage.get('username'));
    setDiscomid(Storage.get('discom_id'));
    setDiscomUserid(Storage.get('discom_user_id'));
  }, []);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.state) {
        setAplicationTrackerid(location.state.aplication_tracker_id);
        setComponentId(location.state.component_id);
      }
      else {
        navigate('/fund-disbursal');
      }
    })();
  }, []);

  const getFields = () => {
    (async () => {
      setIsLoaded(true);
      setError('');
      setFields([]);
      if (roleid && discomid && getaplicationtrackerid && getcomponentid) {
        const params = {
          roleid: roleid,
          discom_id: discomid,
          form_id: 24,
          discom_user_id: discomuserid,
          application_id: getaplicationtrackerid,
          component_id : getcomponentid
        };
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner)
          footerspinner.classList.remove('d-none');
        await Http.get(`/apiv1/fdfields`, { params })
          .then((res) => {
            if (res.fields) {
              if (res.fields && Array.isArray(res.fields)) {
                if (!res.fields.length) setError("No data found.");
                setIsLoaded(true);
                setFields(res.fields);
                setView(res.view);
                setTimeout(() => {
                  init();
                  multijsoninit();
                }, 1000);
              } else {
                setError("Invalid data type.");
              }
            } else if (!res.resultflag) {
              setError(res.message);
            } else {
              setError('No data found.');
            }
          })
          .catch((error) => {
            setError('System error occurred!! please try again.');
          }).finally(() => {
            setIsLoaded(false);
            footerspinner.classList.add('d-none');
          });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      getFields();
    })();
  }, [getaplicationtrackerid]);

  return (
    <>
      {error && (
        <>
          <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
        </>
      )}

      {((!error) && fields?.length > 0) && (
        <>
          <div className="table-responsive RevenueDetailsTable mt-4">
            <div className='caption'>FORM GFR 12-A (UTILIZATION CERTIFICATE)</div>
            <div className="row justify-content-center align-items-center mt-4 mx-0">
              <div className="col-sm-3">
                <div className="form-check ps-0">
                  <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Name of the Utility">Name of the Utility</label>
                  <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-check ps-0">
                  <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="State">State</label>
                  <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-check ps-0">
                  <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Utilisation upto Year">Utilisation upto Year</label>
                  <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />

                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-check ps-0">
                  <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Utilisation upto date">Utilisation upto date</label>
                  <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                </div>
              </div>
            </div>
            <div className="tab-pane">
              <div className="radioYear gfr12A h-auto">
                <div className="row justify-content-center align-items-center px-4 mx-0">
                  <div className='col-sm-6 caption p-0'>Grants position at the beginning of the Financial year</div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Cash">Cash in Hand/Bank (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} /></td>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Unadjusted">Unadjusted advances (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} /></td>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Total">Total (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} /></td>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Block1 field={fields[7]} field1={fields[8]} view={view} />
            <table className="table caption-top mt-4">
              <thead className='text-center'>
                <tr>
                  <th>Unspent balances of grants received in previous year (INR)</th>
                  <th>Interest earned thereon (INR)</th>
                  <th>Interest deposited back to the Government (INR)</th>
                  <th>Total Sanction Amount (INR)</th>
                  <th>Total available Fund (INR)</th>
                  <th>Expenditure incurred (INR)</th>
                  <th>Closing Balances (INR)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[8].childrens[0].key} field={fields[8].childrens[0]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[12].key} field={fields[12]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} /></td>
                </tr>
              </tbody>
            </table>
            <table className="table caption-top mt-4">
              <caption>Component wise utilisation of grants</caption>
              <thead className='text-center'>
                <tr>
                  <th>Grant in aid General (INR)</th>
                  <th>Grant in Aid -Salary (INR)</th>
                  <th>Grant in aid-creation (INR)</th>
                  <th>Total (INR)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[16].key} field={fields[16]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[17].key} field={fields[17]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[18].key} field={fields[18]} /></td>
                </tr>
              </tbody>
            </table>
            <div className="tab-pane">
              <div className="radioYear gfr12A h-auto">
                <div className="row justify-content-center align-items-center px-4 mx-0">
                  <div className='col-sm-6 caption p-0'>Details of grant position at the end of the year</div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Cash">Cash in Hand/Bank (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[19].key} field={fields[19]} /></td>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Unadjusted">Unadjusted advances (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[20].key} field={fields[20]} /></td>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-check ps-0">
                      <label className="form-check-label mb-1 fw-normal fs-regular" htmlFor="Total">Total (INR)</label>
                      <td><FieldComponent options={{ showTitle: false }} fieldName={fields[21].key} field={fields[21]} /></td>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="table caption-top mt-4">
              <caption>Cumulative details of fund utilization :</caption>
              <thead className='text-center'>
                <tr>
                  <th>Particulars</th>
                  <th>GOI</th>
                  <th>CP</th>
                  <th>Own Resources</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cumulative Fund received (INR)</td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[22].key} field={fields[22]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[23].key} field={fields[23]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[24].key} field={fields[24]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[25].key} field={fields[25]} /></td>
                </tr>
                <tr>
                  <td>Cumulative Fund Utilized (INR)</td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[26].key} field={fields[26]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[27].key} field={fields[27]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[28].key} field={fields[28]} /></td>
                  <td><FieldComponent options={{ showTitle: false }} fieldName={fields[29].key} field={fields[29]} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
      }
    </>
  );
}
export default GFR12AFForm;
