import React, { Component } from 'react'
import Evaluation from "../../assets/ImageCompo/Evaluation";
import Monitor from "../../assets/ImageCompo/Monitor";
import InputForm from "../../assets/ImageCompo/InputForm";
import { Link } from "react-router-dom";

export default class CapacityBuilding extends Component {

  render() {
    return (
        <>
          <div className="ActionCenterSection">
            <div className="row gx-0">
              <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                <Link to="/rdss/capacity-building/about" className="d-block w-100 text-decoration-none h-100">
                  <div className="tabPanelInner">
                    <InputForm/>
                    <h5>About RDSS Part-B</h5>
                    <div className="hoverContent">
                      <div>
                        <InputForm/>
                        <h5>About RDSS Part-B</h5>
                        {/*<p>To enter details of Financial, PQ SOP, Energy, REF, Sanction letter and Award details</p>*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                <Link to="/rdss/capacity-building/tm" className="d-block w-100 text-decoration-none h-100">
                  <div className="tabPanelInner">
                    <Monitor/>
                    <h5>Training Management</h5>
                    <div className="hoverContent">
                      <div>
                        <Monitor/>
                        <h5>Training Management</h5>
                        {/*<p>To track physical progress of projects</p>*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                <Link to="/evaluation-form" className="d-block w-100 text-decoration-none h-100 h-100">
                  <div className="tabPanelInner">
                    <Evaluation/>
                    <h5>Training Reports</h5>
                    <div className="hoverContent">
                      <div>
                        <Evaluation/>
                        <h5>Training Reports</h5>
                        {/*<p>To view evaluation of PQ, SOP and REF</p>*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
    )
  }
}
