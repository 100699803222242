import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect } from "../../../assets/js/formbuilder";
import "../../../RdssForms/rdssforms.scss";
import { Http } from '../../../Core/Services/HttpService';
import { useLayout } from "../../../Core/Providers/LayoutProvider";
import { useStateMounted } from '../../../Core/Hooks';
import { Alert } from 'react-bootstrap';
import FieldComponent from "../../../FieldComponent";
import { Storage } from "../../../Core/Services/StorageService";

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={9}>Loss Reduction</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block1Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block1SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1)
                    ? <>
                        <tr>
                            <td colSpan={4} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={9}>MODERNISATION</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block2Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block2SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1)
                    ? <>
                        <tr>
                            <td colSpan={4} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const Block3 = (props: any) => {
    let block3SrNo = 0;
    const [hasBlock3FieldsDelete, setHasBlock3FieldsDeletes] = useState<boolean>(false);
    const [block3Fields, setBlock3fields] = useState<any>([]);
    const [newBlock3Fields, setNewBlock3fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock3fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock3fields(fields1);
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [props.field]);

    useEffect(() => {
        if (block3Fields.length) {
            const hasDelete = (block3Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock3FieldsDeletes(hasDelete);
            block3SrNo = 0;
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }, [block3Fields]);

    const addItem = (block?: any) => {

        if (block3Fields.length < 100) {
            setBlock3fields((prev: any[]) => {
                return [...prev, { fields: newBlock3Fields, delete: false }];
            });
        }
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock3fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={9}>Smart Metering</td>
                {/* <td className='text-end pe-1' style={{ width: "120px" }}>
                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
            </td> */}
            </tr>
            {
                block3Fields.map((item: any, key: number) => {
                    return (item.delete === false) ? <>
                        <tr key={key}>
                            <td className="text-center">{(block3SrNo++) + 1}</td>
                            {
                                item.fields.map((field: any, fkey: number) => {
                                    return <>
                                        <td key={key + '_' + fkey}>
                                            <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                    </>;
                                })
                            }
                            {/* <td className="text-end">{(hasBlock3FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                        </tr>
                    </>
                        : null;
                })
            }
            {
                (props.field1)
                    ? <>
                        <tr>
                            <td colSpan={4} className="text-center"><h6>Total</h6></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[0].key} field={props.field1.childrens[0]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[1].key} field={props.field1.childrens[1]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[2].key} field={props.field1.childrens[2]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[3].key} field={props.field1.childrens[3]} /></td>
                            <td><FieldComponent options={{ showTitle: false }} fieldName={props.field1.childrens[4].key} field={props.field1.childrens[4]} /></td>
                        </tr>
                    </>
                    : null
            }
        </>
    );
}

const CounterpartFundingForms = (props: any) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [discom_name, setDiscomName] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [state_name, setStateName] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);
    const [isSubmittedStatus, SetSubmittedStatus] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [year, setYear] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const layout = useLayout();

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setYear(props.year);
    }, [roleid, discomid, props.year]);

    const getFields = () => {
        (async () => {
            setIsLoaded(true);
            setError('');
            setFields([]);

            if (roleid && discomid && year) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 38,
                    discom_user_id: discomuserid,
                    year: year,
                    module: 'fd_yearly'
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdyearfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setFields(res.fields);
                                SetSubmittedStatus(res.fdsubmittedStatus);
                                layout.set({ fieldsresult: res });
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [year]);

    return (
        <>
            {error && (
                <>
                    <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                </>
            )}

            {
                ((!error) && fields?.length > 0) && (
                    <>
                        <div className="radioYear pe-md-4 py-3 h-auto">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[8].key} field={fields[8]} />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3 mt-3 mt-sm-0">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[9].key} field={fields[9]} />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3 mt-3 mt-sm-0">
                                    <div className="form-check ps-0">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive RevenueDetailsTable mt-4">
                            <table className="table caption-top">
                                <thead className='text-center'>
                                    <tr>
                                        <th style={{ width: "50px" }}>S.No</th>
                                        <th>Name of the District / Project</th>
                                        <th>GoI Grant No./Scheme Code</th>
                                        <th>Name of FI</th>
                                        <th>CP Loan Sanctioned (INR)</th>
                                        <th>CP Loan released (INR)</th>
                                        <th>CP Loan Utilized (INR)</th>
                                        <th>Own fund infused (INR)</th>
                                        <th>Own fund utilized (INR)</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <Block3 field={fields[6]} field1={fields[7]} />
                                    <Block1 field={fields[2]} field1={fields[3]} />
                                    <Block2 field={fields[4]} field1={fields[5]} />
                                </tbody>
                                <tr>
                                    <td colSpan={4} className="text-center"><h6>Total Counterpart Funds</h6></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[12].key} field={fields[12]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[14].key} field={fields[14]} /></td>
                                    <td><FieldComponent options={{ showTitle: false }} fieldName={fields[15].key} field={fields[15]} /></td>
                                </tr>
                            </table>
                        </div>
                    </>
                )
            }
        </>
    );
}
export default CounterpartFundingForms;